import { Component, } from '@angular/core';
import { HostWindowService } from '../../shared/host-window.service';

@Component({
  selector: 'ds-home-news',
  styleUrls: ['./home-news.component.scss'],
  templateUrl: './home-news.component.html'
})

/**
 * Component to render the news section on the home page
 */
export class HomeNewsComponent {
  constructor(protected windowService: HostWindowService) { }
}
