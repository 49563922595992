import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { HostWindowService, WidthCategory } from '../shared/host-window.service';
import { ParagraphItem } from './paragraph-item.interface';

@Component({
  selector: 'ds-basic-page',
  templateUrl: './basic-page.component.html',
  styleUrls: ['./basic-page.component.scss']
})
export class BsicPageComponent {
  readonly maxMobileWidth = WidthCategory.MD;
  @Input() headerImg: string | undefined;
  @Input() paragraphItems: ParagraphItem[] | undefined;
  @Input() htmlTemplate: string | undefined;
  isMobile$: Observable<boolean>;

  constructor(private windowService: HostWindowService) { }

  ngOnInit(): void {
    this.isMobile$ = this.windowService.isUpTo(this.maxMobileWidth);
  }
}
