import { Component } from '@angular/core';
import { HomeNewsComponent as BaseComponent } from '../../../../../app/home-page/home-news/home-news.component';
import { Observable } from 'rxjs';
import { WidthCategory } from 'src/app/shared/host-window.service';

interface Slide { // edw move the interfaces
  id: number | string;
  title: string;
  caption: string;
  img: string;
  link?: string;
}

interface Paragraph {
  id: number | string;
  value: string;
}

interface Donator {
  id: number | string;
  value: string;
}

@Component({
  selector: 'ds-home-news',
  styleUrls: ['./home-news.component.scss'],
  templateUrl: './home-news.component.html'
})

/**
 * Component to render the news section on the home page
 */
export class HomeNewsComponent extends BaseComponent {
  readonly promoVideo = 'https://res.cloudinary.com/bloomsbury-publishing-public/video/upload/f_auto%2Cq_auto/aklott01/CMS/History_Day_2020.mp4#t=0.1';
  readonly maxMobileWidth = WidthCategory.MD;
  public isMobile$: Observable<boolean>;

  ngOnInit(): void {
    this.isMobile$ = this.windowService.isUpTo(this.maxMobileWidth);
  }
  
  readonly slides: Slide[] = [
    {
      id: 1,
      title: 'Canon of Medicine',
      caption: `
        Avicenna's work, initially published in 1025 in Arabic, became an essential text in medical science. 
        The series of editions, commentaries, and translations produced after its publication are a testament 
        to its importance. Among these editions, the most significant is the version edited by Andrea Alpago whose 
        first edition, published in Venice in 1527, served as the basis of most subsequent Latin editions.
      `,
      img: '../../../../../assets/images/home/carousel/AKBooks166_green.jpg'
    },
    {
      id: 2,
      title: 'Muwatta’ al-Imam Muhammad',
      caption: `
        The Muwatta by Muhammad ibn al-Hasan al-Shaybani is the transmission of the Muwatta of Imam Malik. 
        This narration of the Muwatta of Imam Malik differs from most of the other transmissions in a number of respects. 
        This fascinating manuscript is written in neat handwriting with margins full of annotations and commentaries. 
        It provides not only a deep insight into the early community of the companions but also an insight into the early interaction between the Hanafis and Malikis.
      `,
      img: '../../../../../assets/images/home/carousel/AKBooks388.jpg'
    },
    {
      id: 3,
      title: 'Ash-Shifa',
      caption: `
        Known initially as Kitab Al-Shifa Bi Ta'rif Huquq Al-Mustafa by Qadi Iyad ibn Musa (1083). 
        It describes Prophet Muhammad's life, his qualities as well as his miracles. This work, of 
        unquestioned admiration throughout the Muslim world, soon acquired a sanctity of its own as 
        believers thought that it would protect households from any harm.
      `,
      img: '../../../../../assets/images/home/carousel/AKBooks591.jpg'
    },
    {
      id: 4,
      title: 'Gulshan-i Raz',
      caption: `
        The Rose Garden of Secrets is a mystical work by Shabestari, composed during the 14th century 
        as a reply to the 17 metaphysical questions posed to 'the Sufi literati of Tabriz' by Rukh Al 
        Din Amir Husayn Harawi. The Gulshan-i Raz became one of the most notable classical Persian 
        poetry works; a collection of Sufi poems composed in rhyming couplets.
      `,
      img: '../../../../../assets/images/home/carousel/AKBooks516.jpg'
    },
    {
      id: 5,
      title: 'Dabestān-e Mazāheb',
      caption: `
        The Dabestān-e Mazāheb, also transliterated as Dabistān-i Mazāhib, is an examination and comparison of South Asian 
        religions and sects of the mid-17th century. The work is written in Persian, probably having been composed in about 1655 CE. 
        This copy is beautifully illuminated, copied in 1801 CE.
      `,
      img: '../../../../../assets/images/home/carousel/AKBooks297.jpg'
    }
  ];

  readonly paragraphs: Paragraph[] = [
    // na kanw ena json na to pairnei apo ekei
    {
      id: 1,
      value: `
        The Aga Khan Library is a significant library for scholarly engagement in the field of Islamic studies with a
        focus on Ismaili studies and Muslim civilisations. The library houses a growing collection of over 50,000 titles,
        covering a range of subjects, historical and contemporary, on Islam in English, Arabic, Persian, Turkish,
        Gujarati, and Sindhi, among many other languages.
      `
    },
    {
      id: 2,
      value: `
        At present, the Aga Khan Library holds more than 1000 titles in its rare books collection. The collection includes
        manuscripts, out-of-print and first editions of essential titles in the field of Islamic studies. These books have
        been acquired either through generous donations or proactive acquisitions. At the heart of the collection, there
        is an impressive selection of Ottoman books, as well as precious items from the personal collections of renowned
        academics: the late Prof Annemarie Schimmel, the late Prof Peter Avery, and Dr Farhad Daftary. The library is
        fortunate to have benefitted from the donation of the personal archive of the late Prof. Mohammed Arkoun.
      `
    },
    {
      id: 3,
      value: `
        In 2019, the Aga Khan Library embarked on developing the Digital Collections platform, a project intended to
        preserve, display, and facilitate research in a selection of materials from its rare books collection. The Aga
        Khan Library recognises the fundamental support of generous donors for this project.
      `
    },
  ];

  readonly donators: Donator[] = [
    {
      id: 1,
      value: 'Bahadurali & Jenny Ahmed Family'
    },
    {
      id: 2,
      value: 'Malik & Shela Karimi Family'
    },
    {
      id: 3,
      value: 'Shiraz & Naseem Kassam Family'
    }
  ];
}
