<!-- <div class="container">
    <div class="search-wrapper">
        <input 
            class="search-input"
            type="text" 
            [placeholder]="placeholder" 
            autocomplete="off"
            [(ngModel)]="searchInput" />
        <input class="submit-btn" (click)="onSearch()" />
    </div>
    <div class="advanced_search playsearch-find"></div>
</div> -->


<div class="search-container" [class.container]="!isSmallSearchInput">
    <input
        type="text"
        autocomplete="off"
        class="search-input"
        [class.small-height]="isSmallSearchInput"
        [placeholder]="placeholder" [(ngModel)]="searchInput" >
    <button
        class="submit-btn"
        [class.small-btn]="isSmallSearchInput"
        (click)="onSearch()"></button>
</div>