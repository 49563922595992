import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'dsTextReplacement'
})
export class TextReplacementPipe implements PipeTransform {
  transform(value: string, target: string, replacement: string) {
    if (!target || !target || !replacement) {
      return value;
    }

    const targetRegex = new RegExp(target, 'g');
    return value.replace(targetRegex, replacement);
  }
}