import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

export interface TransformationInfo {
  target: string;
  replacement: string;
}

@Injectable({
  providedIn: "root",
})
export class GlobalTextTransformerService {
  private renderer: Renderer2;
  private observer: MutationObserver;
  private readonly pattern = ":::";
  private readonly replacement = ", ";

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === "childList") {
          Array.from(mutation.addedNodes).forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              this.walkAndTransform(node);
            }
          });
        }
      }
    });
  }

  public globalTextReplacement(element: HTMLElement): void {
    this.walkAndTransform(element);
    this.observeDOMChanges(element);
  }

  private walkAndTransform(node: Node): void {
    const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, null);
    let textNode: Node | null;

    while ((textNode = walker.nextNode())) {
      if (textNode.nodeType === Node.TEXT_NODE && textNode.nodeValue) {
        const transformedText = textNode.nodeValue.replace(new RegExp(this.pattern, 'g'), this.replacement);
        this.renderer.setValue(textNode, transformedText);
      }
    }
  }

  private observeDOMChanges(element: HTMLElement): void {
    this.observer.observe(element, { childList: true, subtree: true });
  }
}
