import { AfterViewInit, Component, NgZone } from "@angular/core";
import OpenSeadragon from "openseadragon";
import { OpenseadragonViewerService } from "./openseadragon-viewer.service";

@Component({
  selector: "ds-openseadragon-viewer",
  templateUrl: "./openseadragon-viewer.component.html",
  styleUrls: ["./openseadragon-viewer.component.scss"],
})
export class OpenseadragonViewerComponent implements AfterViewInit {

  constructor(
    private openseadragonViewerService: OpenseadragonViewerService,
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.initializeOpenSeadragon();
  }

  private initializeOpenSeadragon() {
    this.openseadragonViewerService.tileSources$.subscribe(tileSources => {
      this.openseadragonViewerService.openSeadragonViewer = this.zone.runOutsideAngular(() => OpenSeadragon({
        id: "seadragonViewer",
        sequenceMode: true,
        showReferenceStrip: true,
        // @ts-expect-error
        showReferenceAutoFade: false,
        referenceStripSizeRatio: 0.2,
        prefixUrl: "https://openseadragon.github.io/openseadragon/images/",
        tileSources: tileSources
      }));
    });
  }
}
