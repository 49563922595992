import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Viewer } from "openseadragon";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { scrollToTop } from "../utils/window.utils";

export interface BookTableContentsLite {
  front: string;
  main: string;
  back: string;
}

@Injectable({
  providedIn: 'root'
})
export class OpenseadragonViewerService {
  openSeadragonViewer: Viewer;
  private _tileSources$ = new BehaviorSubject<any | undefined>(undefined);
  private _bookTableContentsLite = new BehaviorSubject<BookTableContentsLite | undefined>(undefined);

  get tileSources$(): Observable<any> {
    return this._tileSources$.asObservable();
  }

  get bookTableContentsLite$(): Observable<BookTableContentsLite> {
    return this._bookTableContentsLite.asObservable();
  }

  constructor(private http: HttpClient) { }

  goToViewerPage(input: string | number) {
    const page = Number(input);
    if (isNaN(page)) {
      return;
    }
    this.openSeadragonViewer.goToPage(page - 1);
    scrollToTop();
  }

  initBookViewer(bookId: string) {
    this.openSeadragonViewer = undefined;
    this.http
      .get(`${environment.bookServerUrl}?id=${bookId}`)
      .subscribe((data: any) => {
        this._tileSources$.next(data.tileSources);
      });
  }

  initBookTableContentsLite(tableContentLite: BookTableContentsLite) {
    this._bookTableContentsLite.next({
      back: tableContentLite.back,
      front: tableContentLite.front,
      main: tableContentLite.main
    });
  }
}