import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() { }

  goToExternalLink(url: string) {
    const isUserConfirmed = confirm('You are going to redirect to an external link. Do you wish to continue?');
    if (isUserConfirmed) {
      window.open(url, '_blank');
    }
  }
}
