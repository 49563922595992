export interface LanguageEntry {
    display: string,
    value: string,
    otherInformation: any,
    type: string,
} 

export const entries: LanguageEntry[] = [
  {
    display: "N/A",
    value: "",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Arabic",
    value: "ara",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "English",
    value: "eng",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "French",
    value: "fre",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "German",
    value: "ge",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Gujarati",
    value: "guj",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Indic languages",
    value: "inc",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Indo-European languages",
    value: "ine",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Multiple languages",
    value: "mul",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Ottoman Turkish",
    value: "ota",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Persian",
    value: "PER",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Spanish",
    value: "spa",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Turkish",
    value: "tr",
    otherInformation: {},
    type: "vocabularyEntry",
  },
  {
    display: "Urdu",
    value: "urd",
    otherInformation: {},
    type: "vocabularyEntry",
  },
];

Object.freeze(entries);
