import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HostWindowService, WidthCategory } from '../shared/host-window.service';
import { MenuID } from '../shared/menu/menu-id.model';
import { MenuService } from '../shared/menu/menu.service';
import { NavigationService } from '../shared/navigation.service';
import { AuthService } from '../core/auth/auth.service';

/**
 * Represents the header with the logo and simple navigation
 */
@Component({
  selector: 'ds-header',
  styleUrls: ['header.component.scss'],
  templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit {
  /**
   * Whether user is authenticated.
   * @type {Observable<string>}
   */
  public isAuthenticated$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public goToExternalLink: Function;

  menuID = MenuID.PUBLIC;
  maxMobileWidth = WidthCategory.MD;

  constructor(
    navigationService: NavigationService,
    protected router: Router,
    protected menuService: MenuService,
    protected windowService: HostWindowService,
    protected authService: AuthService
  ) {
    this.goToExternalLink = navigationService.goToExternalLink;
  }

  ngOnInit(): void {
    this.isMobile$ = this.windowService.isUpTo(this.maxMobileWidth);
    this.isAuthenticated$ = this.authService.isAuthenticated();
  }

  public toggleNavbar(): void {
    this.menuService.toggleMenu(this.menuID);
  }
}
