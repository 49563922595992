import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ds-custom-search-input',
  templateUrl: './custom-search-input.component.html',
  styleUrls: ['./custom-search-input.component.scss']
})
export class CustomSearchInputComponent {
  @Input() placeholder = 'Search';
  @Input() isSmallSearchInput = false;
  @Output() searchClicked = new EventEmitter<string>();
  public searchInput = '';

  onSearch() {
    this.searchClicked.emit(this.searchInput);
  }
}
