<div class="jumbotron jumbotron-fluid mt-ncs">
  <div class="container">
    <section class="text-center">
      <ngb-carousel>
        <ng-template ngbSlide *ngFor="let slide of slides">
          <div [class.caption-image-wrapper-large-screen]="!(isMobile$ | async)" [class.caption-image-wrapper-small-screen]="(isMobile$ | async)">
            <div class="slide-title-caption">
              <h3 class="slide-title">{{ slide.title }}</h3>
              <p class="slide-caption">{{ slide.caption }}</p>
              <br>
              <a *ngIf="slide.link" class="slide-link" [href]="slide.link">
                View title
              </a>
            </div>
            <img class="slide-image" [src]="slide.img">
          </div>
        </ng-template>
      </ngb-carousel>
    </section>

    <section>
      <p class="text-justify" *ngFor="let paragraph of paragraphs">
        {{ paragraph.value }}
      </p>
    </section>
    
    <section>
      <div class="align-items-start text-center">
        <div class="donators-and-video-wrapper" [class.donators-and-video-wrapper-mob]="(isMobile$ | async)">
          <div class="donators-wrapper">
            <div class="centering-donators">
              <h4 class="mb-4 bad-script-fonts-regular">
                <i>The Aga Khan Library gratefully acknowledges the generosity of</i>
              </h4>
              <div *ngFor="let donator of donators">
                {{ donator.value }}
              </div>
            </div>
          </div>
          <div class="height">
            <video *ngIf="(isMobile$ | async)" width="100%" height="100%" controls>
              <source src="assets/videos/History_Day_2020.mp4">
            </video>
            <video *ngIf="!(isMobile$ | async)" width="auto" height="100%" controls>
              <source src="assets/videos/History_Day_2020.mp4">
            </video>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="row align-items-start text-center">
        <div class="col">
          <div class="card" routerLink="/community-list">
            <img class="card-img-top" src="../../../../../assets/images/home/Explore.jpg" alt="Collections image">
            <div class="card-body" [class.card-body-mobile]="(isMobile$ | async)">
              <p class="card-text" [class.card-text-mobile]="(isMobile$ | async)">Explore Collections</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card" routerLink="/about" >
            <img class="card-img-top" src="../../../../../assets/images/home/About.jpg"alt="About image">
            <div class="card-body" [class.card-body-mobile]="(isMobile$ | async)">
              <p class="card-text" [class.card-text-mobile]="(isMobile$ | async)">About</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card" routerLink="/help">
            <img class="card-img-top" src="../../../../../assets/images/home/Help.jpg" alt="Help image">
            <div class="card-body" [class.card-body-mobile]="(isMobile$ | async)">
              <p class="card-text" [class.card-text-mobile]="(isMobile$ | async)">Help</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
