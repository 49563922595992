<div class="jumbotron jumbotron-fluid mt-ncs">
    <div class="container">
        <section *ngIf="headerImg">
            <img
                class="img-fluid main-img rounded"
                style="height: 350px; margin: auto;"
                alt="Image from Moroccan Qur'an (19th century)"
                [src]="headerImg">
        </section>
    
        <section *ngIf="paragraphItems">
            <div
                class="text-justify mt-4"
                [ngClass]="(isMobile$ | async) ? 'flex-paragraph-mobile' : 'flex-paragraph'"
                *ngFor="let item of paragraphItems">
                <p *ngIf="item.paragraph">{{ item.paragraph }}</p>
                <img *ngIf="item.img" [ngClass]="(isMobile$ | async) ? 'full-width' : 'half-width'" [src]="item.img.src" alt="item.img.alt">
                <video
                    *ngIf="item.video"
                    class="rounded"
                    [ngClass]="(isMobile$ | async) ? 'full-width' : 'half-width'"
                    height="100%"
                    controls
                    preload="none"
                    poster="https://res.cloudinary.com/bloomsbury-publishing-public/image/upload/f_auto%2Cq_auto/aklott01/CMS/video_thumbnail.jpg">
                    <source [src]="item.video.src" [type]="item.video.type">
                </video>
            </div>
        </section>

        <ng-content></ng-content>
    </div>
</div>