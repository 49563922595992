<header class="main-site-header">
  <div class="header-wrapper">
    <div *ngIf="!(isMobile$ | async); else mobileView" class="container logo-and-navigation-tabs-wrapper">
      <div class="logo-wrapper">
        <a (click)="goToExternalLink('https://agakhanlibrary.org/')">
          <img src="../../assets/images/header/AKL_Logo_350.png" alt="Logo" class="logo" />
        </a>
      </div>

      <div class="navigation-tabs-wrapper">
        <div class="navigation-tab" routerLink="home" routerLinkActive="navigation-tab-is-active">
          Home
        </div>
        <div class="navigation-tab stylish-line" routerLink="about" routerLinkActive="navigation-tab-is-active">
          About
        </div>
        <div class="navigation-tab stylish-line" routerLink="community-list" routerLinkActive="navigation-tab-is-active">
          Collections
        </div>
        <div ngbDropdown>
          <div ngbDropdownToggle id="browseDropdown" class="navigation-tab stylish-line dropdown-toggle">
            Browse
          </div>
          <div ngbDropdownMenu>
            <div ngbDropdownItem *ngFor="let page of browsePages" class="cursor-pointer"
              (click)="navigateToBrowsePage(page)">
              {{ page.name }}
            </div>
          </div>
        </div>
        <div class="navigation-tab stylish-line" routerLink="help" routerLinkActive="navigation-tab-is-active">
          Help
        </div>
        <a class="navigation-tab stylish-line" (click)="goToExternalLink('https://agakhanlibrary.org/')">
          Aga Khan Library
        </a>
        <div ngbDropdown *ngIf="isAuthenticated$ | async">
          <div ngbDropdownToggle id="accountIcon" class="navigation-tab stylish-line">
            <div class="account-icon"></div>
          </div>
          <div ngbDropdownMenu>
            <div ngbDropdownItem class="cursor-pointer" (click)="navigateToMySpace()">
              My Space
            </div>
            <div ngbDropdownItem class="cursor-pointer logout" routerLink="logout">
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #mobileView>
      <div class="container logo-and-mobile-navbar-wrapper">
        <div id="mobile-navbar-toggler">
          <button class="btn" type="button" (click)="collapse.toggle()" [attr.aria-expanded]="isCollapsed">
            <span class="fas fa-bars fa-fw fa-xl toggler-icon" aria-hidden="true"></span>
          </button>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="mobile-nav-menu">
          <div class="card">
            <div class="card-body">
              <p routerLink="home" routerLinkActive="navigation-tab-is-active-mobile">Home</p>
              <p routerLink="about" routerLinkActive="navigation-tab-is-active-mobile">About</p>
              <p routerLink="community-list" routerLinkActive="navigation-tab-is-active-mobile">Collections</p>
              <div ngbDropdown>
                <p ngbDropdownToggle id="browseDropdown" class="dropdown-toggle">
                  Browse
                </p>
                <div ngbDropdownMenu>
                  <p ngbDropdownItem *ngFor="let page of browsePages" class="cursor-pointer"
                    (click)="navigateToBrowsePage(page)">
                    {{ page.name }}
                  </p>
                </div>
              </div>
              <p routerLink="help" routerLinkActive="navigation-tab-is-active-mobile">Help</p>
              <p (click)="goToExternalLink('https://agakhanlibrary.org/')">Aga Khan Library</p>
              <ng-container *ngIf="isAuthenticated$ | async">
                <hr>
                <p
                  routerLink="mydspace"
                  [queryParams]="{ configuration: 'supervisedWorkspace' }"
                  routerLinkActive="navigation-tab-is-active-mobile"
                  (click)="navigateToMySpace()">
                  My Space
                </p>
                <p style="color: #d93025;" routerLink="logout">Logout</p>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="logo-wrapper">
          <a (click)="goToExternalLink('https://agakhanlibrary.org/')">
            <img style="height: 56px;" src="../../assets/images/header/AKL_Logo_350.png" alt="Logo" class="logo" />
          </a>
        </div>
        <div class="mobile-search-icon" (click)="showSearchbar = !showSearchbar">
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="!(isMobile$ | async) || showSearchbar" class="search-container">
    <ds-custom-search-input [placeholder]="'Search AKL Digital Collections'" (searchClicked)="onSearch($event)">
    </ds-custom-search-input>
  </div>
</header>
