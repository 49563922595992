import { Pipe, PipeTransform } from "@angular/core";
import { entries } from "src/assets/languages";

@Pipe({
  name: "dsLanguageCodeReplacement",
})

/**
 * Pipe for replacing ISO language code with display value.
 */
export class LanguageCodeReplacementPipe implements PipeTransform {
  /**
   * @param {string} value ISO code to be replaced
   * @returns {string} Display language value
   */
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const entry = entries.find(entry => value === entry.value || value.includes(` "${entry.value}"`));

    if (entry) {
      return value.includes(` "${entry.value}"`)
        ? value.replace(` "${entry.value}"`, ` "${entry.display}"`)
        : entry.display;
    }

    return value;
  }
}
