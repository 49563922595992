import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderComponent as BaseComponent } from '../../../../app/header/header.component';
import { BrowsePage } from './navigation-tabs.interface';
import { environment } from 'src/environments/environment';

/**
 * Represents the header with the logo and simple navigation
 */
@Component({
  selector: 'ds-header',
  styleUrls: ['header.component.scss'],
  templateUrl: 'header.component.html',
})
export class HeaderComponent extends BaseComponent implements OnInit {
  public isNavBarCollapsed$: Observable<boolean>;
  public readonly baseUrl = environment.baseUrl;  
  public readonly browsePages: BrowsePage[] = [
    { name: 'Date Issued', path: 'dateissued' },
    { name: 'Author', path: 'author' },
    { name: 'Title', path: 'title' },
    { name: 'Subject', path: 'subject' },
    { name: 'Language', path: 'language' }

  ];
  public isCollapsed = true;
  public showSearchbar = false;
  private readonly SPC_PAGE_QUERY_PARAM = {
    'spc.page': 1
  };
  private readonly QUERY_KEY = 'query';
  private readonly SEARCH_ROUTE = '/search';

  ngOnInit() {
    super.ngOnInit();
    this.isNavBarCollapsed$ = this.menuService.isMenuCollapsed(this.menuID);
  }

  onSearch(searchInput: string) {
    const queryParams = {
      ...this.SPC_PAGE_QUERY_PARAM,
      ...(searchInput && { [this.QUERY_KEY]: searchInput })
    };
    this.router.navigate([this.SEARCH_ROUTE], { queryParams });
  }

  navigateToBrowsePage(page: BrowsePage) {
    this.router.navigate(['browse', page.path]);
  }

  navigateToMySpace() {
    this.router.navigate(['mydspace'], { queryParams: { configuration: 'supervisedWorkspace' }});
  }
}
